import React from 'react';

class Phone extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      test: '12',
    };
  }

  render() {
    var phone_number = '(929) 5909255';

    if (this.props.continent == 'Europe') {
      phone_number = '(020) 45770272';
    } else {
      switch (this.props.country) {
        case 'AU':
          phone_number = '(02) 88800313';
          break;
        case 'CA':
          phone_number = '(437) 8860987';
          break;
        case 'NZ':
          phone_number = '(04) 8881388';
          break;
        case 'SG':
          phone_number = '31594885';
          break;
        case 'GB':
          phone_number = '(020) 45770272';
          break;
        case 'US':
          phone_number = '(929) 5909255';
          break;
        case 'AM':
          phone_number = '01 220 20 40';
          break;
        default:
          phone_number = '(929) 5909255';
          break;
      }
    }

    return phone_number;
  }
}

export default Phone;
